// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` /*
.mainContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  position: absolute;
  right: 20px;
  top: 20px;
}

.div-center {
  width: 400px;
  height: 400px;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;
  display: table;
}

div.content {
  display: table-cell;
  vertical-align: middle;
}*/`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"CAAC;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EA4CC","sourcesContent":[" /*\n.mainContainer {\n  flex-direction: column;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n\n.titleContainer {\n  display: flex;\n  flex-direction: column;\n  font-size: 64px;\n  font-weight: bolder;\n  align-items: center;\n  justify-content: center;\n}\n\n.buttonContainer {\n  position: absolute;\n  right: 20px;\n  top: 20px;\n}\n\n.div-center {\n  width: 400px;\n  height: 400px;\n  background-color: #fff;\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  margin: auto;\n  max-width: 100%;\n  max-height: 100%;\n  overflow: auto;\n  padding: 1em 2em;\n  display: table;\n}\n\ndiv.content {\n  display: table-cell;\n  vertical-align: middle;\n}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
