import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import Main from './Components/Main';
import Welcome from './Components/Welcome';
import Login from './Components/Login';
import Apps from './Components/Apps';

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))

    if (!user || !user.token) {
      setLoggedIn(false)
      return
    }

    fetch("https://nafis.shaiakbarov.fvds.ru/api/verify", {
      method: "POST",
      headers: {
        'jwt-token': user.token
      }
    })
    .then(r => r.json())
    .then(r => {
      setLoggedIn('success' === r.message)
      setUsername(user.name || "")
    })
  }, [])

  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Welcome username={username} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
            <Route path="/main" element={<Main username={username} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
            <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setUsername={setUsername}/>} />
            <Route path="/apps" element={<Apps/>} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
