import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const Welcome = (props) => {
  const { loggedIn, username } = props
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))

    if (!user || !user.token) {
      props.setLoggedIn(false)
      return
    }

    navigate("/main")
  }, [])

  const onButtonClick = () => {
    if (loggedIn) {
      localStorage.removeItem("user")
      props.setLoggedIn(false)
    } else {
      navigate("/login")
    }
  }


  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-light">
        <div className="mx-auto p-4 text-center">
            <h1 className="text-success">Добро пожаловать!</h1>
            <h3 className="mb-3">
                Android CI/CD panel
            </h3>
        </div>
        <div style={{position: 'absolute', right: '20px',top: '10px'}}>
                <button type="button"
                        className="btn btn-primary"
                        data-toggle="button"
                        aria-pressed="false"
                        autoComplete="off"
                        type="button"
                        onClick={onButtonClick}>
                    {loggedIn ? "Выйти" : "Войти"}
                </button>
        </div>
    </div>
  );
}

export default Welcome
