import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Button, Table, Modal, Form, Stack } from "react-bootstrap";
import axios from 'axios';

const Apps = (props) => {
  const [apps, setApps] = useState([]);
  const [token, setToken] = useState('')

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setStoreName('')
    setProvider('')
    setPackage('')
    setKey('')
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const [storeName, setStoreName] = useState('')
  const [provider, setProvider] = useState('')
  const [packageName, setPackage] = useState('')
  const [key, setKey] = useState('')

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))

    if (!user || !user.token) {
      navigate("/")
    }

    setToken(user.token)

    getApps(user.token)

  }, [])

  async function getApps(token) {
    try {
      const response =
      await axios({
        url: 'https://nafis.shaiakbarov.fvds.ru/api/apps',
        method: 'get',
        headers: {
          'jwt-token': token
        }
      })
      .then(response => {
        if ('success' === response.data.status) {
          console.log(response.data.data)
          setApps(response.data.data)
        } else {
          console.log(response.message)
        }
      })
      .catch(err => {
        console.log(err);
      });

    } catch (error) {
      console.error('Error get apps:', error);
    }
  }

  async function saveApp() {
    try {
      const response =
      await axios.post('https://nafis.shaiakbarov.fvds.ru/api/addApp',
      {storeName, provider, packageName, key},
      {
        headers: {
          'jwt-token': token
        }
      });
      if ('success' === response.data.message) {
        handleClose()
        window.location.reload();
      } else {
        // setMessage(response.data.message);
      }

    } catch (error) {
      console.error('Error logging in:', error);
    }
  }

  const onSave = () => {
    saveApp()
  }

  const backSubmit = () => {
    navigate("/main")
  }

  return (
  <>
  	<Navbar className="bg-body-tertiary">
          <Container>
          <Stack direction="horizontal" gap={4}>
              <Button variant="primary" onClick={handleShow}>Добавить новое</Button>{' '}
              </Stack>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
              </Navbar.Collapse>
          </Container>
      </Navbar>
    <div style={{position:'absolute', left:'20px',top:'10px'}}>
        <button type="button"
                className="btn btn-outline-primary"
                data-toggle="button"
                aria-pressed="false"
                autoComplete="off"
                type="button"
                onClick={backSubmit}>
            Назад
        </button>
    </div>
    <Container>
          <Table striped bordered hover responsive="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Название в сторе</th>
                <th>Провайдер</th>
                <th>Название пакета</th>
                <th>Алиас</th>
              </tr>
            </thead>
            <tbody>
              {apps.map((el) => {
                  return(
                      <tr key={el.id}>
                          <td>{el.id}</td>
                          <td>{el.name}</td>
                          <td>{el.provider}</td>
                          <td>{el.package}</td>
                          <td>{el.key}</td>
                      </tr>
                  )
              })}
            </tbody>
          </Table>
      </Container>

      <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
          <Modal.Title>Новое приложение</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form>
                  <Form.Group className="mb-3" controlId="storeName">
                      <Form.Control
                          type="text"
                          placeholder="Название в сторе"
                          onChange={(e) => setStoreName(e.target.value)}
                          autoFocus
                      />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="provider">
                      <Form.Control
                          type="text"
                          placeholder="Провайдер, например, ujin"
                          onChange={(e) => setProvider(e.target.value)}
                      />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="package">
                      <Form.Control
                          type="text"
                          placeholder="Название пакета, например, tech.ujin..."
                          onChange={(e) => setPackage(e.target.value)}
                      />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="key">
                      <Form.Control
                          type="text"
                          placeholder="Название файла с ключом"
                          onChange={(e) => setKey(e.target.value)}
                      />
                  </Form.Group>

              </Form>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="success" onClick={onSave}>
          Создать
          </Button>
          </Modal.Footer>
      </Modal>

    </>
      );
}

export default Apps
