import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))

    if (!user || !user.token) {
      props.setLoggedIn(false)
      return
    } else {
      setUsername(user.name)
      navigate("/")
    }
  })

  const handleSubmit = async () => {
    if ("" === username || "" === password) {
      setMessage("Please enter a username and a password")
      return
    }

    try {
      const response =
      await axios.post('https://nafis.shaiakbarov.fvds.ru/api/auth',
      {username,password});
      setMessage(response.data.message);
      if ('success' === response.data.message) {
        localStorage.setItem("user", JSON.stringify({name: username, token: response.data.token}))
        props.setLoggedIn(true)
        props.setUsername(username)
        navigate("/")
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setMessage('An error occurred');
    }
  }

  const backSubmit = () => {
    navigate("/")
  }

  return (
          <div className="row d-flex justify-content-center align-items-center vh-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5" style={{width:'400px'}}>
              <div className="card shadow-2-strong">
                <div className="card-body p-5 text-center">
                  <h3 className="mb-5">Вход</h3>

                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="text"
                          placeholder="Введите имя"
                          className="form-control form-control-lg"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                      />
                  </div>

                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="password"
                          placeholder="Введите пароль"
                          className="form-control form-control-lg"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          />
                  </div>

                  <button
                      data-mdb-button-init
                      data-mdb-ripple-init
                      className="btn btn-primary btn-lg btn-block"
                      type="submit"
                      onClick={handleSubmit}
                      >
                      Войти
                  </button>
                  {message && <p>{message}</p>}
                </div>
              </div>
            </div>
          <div style={{position:'absolute', left:'20px',top:'20px'}}>
          <button type="button"
                  className="btn btn-light"
                  data-toggle="button"
                  aria-pressed="false"
                  autoComplete="off"
                  type="button"
                  onClick={backSubmit}>
              Назад
          </button>
  </div>

          </div>
      );
}

export default Login;
