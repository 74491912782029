import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Navbar, Container, Button, Table, Modal, Form, Stack} from "react-bootstrap";
import axios from 'axios';

const Main = (props) => {
  const { loggedIn } = props
  const [username, setUsername] = useState('');
  const [tableData, setData] = useState([]);

  const [apps, setApps] = useState([]);
  const [fastlaneTasks, setFastlane] = useState([]);
  const [buildTypes, setBuildTypes] = useState([]);
  const [branches, setBranches] = useState([]);
  const [version, setVersion] = useState('');
  const [codeVersion, setCodeVersion] = useState('');

  // Selected items from Form
  const [selectedApp, setSelectedApp] = useState('');
  const [selectedLane, setSelectedLane] = useState('');
  const [selectedBuildType, setSelectedBuildType] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))

    if (!user || !user.token) {
      props.setLoggedIn(false)
      navigate("/")
      return
    } else {
      setUsername(user.name)
      getData(user.token)
      getApps(user.token)
      getTasks(user.token)
      getBranches(user.token)
      getBuildTypes(user.token)
      getVersions(user.token)
    }
  }, [])

  async function getData(token) {
    try {
      const response =
      await axios({
        url: 'https://nafis.shaiakbarov.fvds.ru/api/data',
        method: 'get',
        headers: {
          'jwt-token': token
        }
      })
      .then(response => {
        if ('success' === response.data.status) {
          // console.log(response.data.data)
          setData(response.data.data)
        } else {
          console.log(response.message)
        }
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err);
      });

    } catch (error) {
      console.error('Error logging in:', error);
    }
  }

  async function getBranches(token) {
    try {
      const response =
      await axios({
        url: 'https://nafis.shaiakbarov.fvds.ru/api/getBranches',
        method: 'get',
        headers: {
          'jwt-token': token
        }
      })
      .then(response => {
        if ('success' === response.data.message) {
          const array = response.data.data.map(removeSpaces)
          setBranches(array)
        } else {
          console.log(response.message)
        }
      })
      .catch(err => {
        console.log(err);
      });

    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function getApps(token) {
    try {
      const response =
      await axios({
        url: 'https://nafis.shaiakbarov.fvds.ru/api/apps',
        method: 'get',
        headers: {
          'jwt-token': token
        }
      })
      .then(response => {
        if ('success' === response.data.status) {
          // console.log(response.data.data)
          setApps(response.data.data)
        } else {
          console.log(response.message)
        }
      })
      .catch(err => {
        console.log(err);
      });

    } catch (error) {
      console.error('Error get apps:', error);
    }
  }

  async function getTasks(token) {
    try {
      const response =
      await axios({
        url: 'https://nafis.shaiakbarov.fvds.ru/api/getTasks',
        method: 'get',
        headers: {
          'jwt-token': token
        }
      })
      .then(response => {
        if ('success' === response.data.status) {
          // console.log(response.data.data)
          setFastlane(response.data.data)
        } else {
          console.log(response.message)
        }
      })
      .catch(err => {
        console.log(err);
      });

    } catch (error) {
      console.error('Error get apps:', error);
    }
  }

  async function getBuildTypes(token) {
    try {
      const response =
      await axios({
        url: 'https://nafis.shaiakbarov.fvds.ru/api/getBuildTypes',
        method: 'get',
        headers: {
          'jwt-token': token
        }
      })
      .then(response => {
        if ('success' === response.data.status) {
          // console.log(response.data.data)
          setBuildTypes(response.data.data)
        } else {
          console.log(response.message)
        }
      })
      .catch(err => {
        console.log(err);
      });

    } catch (error) {
      console.error('Error get apps:', error);
    }
  }

  async function getVersions(token) {
    try {
      const response =
      await axios({
        url: 'https://nafis.shaiakbarov.fvds.ru/api/getVersions',
        method: 'get',
        headers: {
          'jwt-token': token
        }
      })
      .then(response => {
        if ('success' === response.data.status) {
          // console.log(response.data.data)
          setVersion(response.data.data[0].version)
          setCodeVersion(response.data.data[0].codeVersion)
        } else {
          console.log(response.message)
        }
      })
      .catch(err => {
        console.log(err);
      });

    } catch (error) {
      console.error('Error get apps:', error);
    }
  }

  async function createBuild() {
    const user = JSON.parse(localStorage.getItem("user"))

    try {
      const response =
      await axios.post('https://nafis.shaiakbarov.fvds.ru/api/createBuild',
      {selectedApp, selectedLane, selectedBuildType, selectedBranch, version, codeVersion, username},
      {
        headers: {
          'jwt-token': user.token
        }
      });
      if ('success' === response.data.message) {
        handleClose()
        window.location.reload();
      } else {
        // setMessage(response.data.message);
      }

    } catch (error) {
      console.error('Error logging in:', error);
    }
  }


  function removeDuplicates(data) {
    return [...new Set(data)]
  }

  function removeSpaces(value, index, array) {
    return value.replace(/\s+/g, ' ').trim();
  }

  const onApps = () => {
    navigate("/apps")
  }

  const onStartBuild = () => {
    if ("" === selectedApp || "" === selectedLane) {
      console.log("Please choose app name and/or lane")
      return
    }
    createBuild()
  }

  const onLogout = () => {
    localStorage.removeItem("user")
    props.setLoggedIn(false)
    navigate("/")
  }

    return (
    <>
        <Navbar className="bg-body-tertiary">
            <Container>
            <Stack direction="horizontal" gap={4}>
                <Button variant="primary" onClick={onApps}>Приложения</Button>{' '}
                <Button variant="primary" onClick={handleShow}>Добавить сборку</Button>{' '}
            </Stack>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
            <Stack direction="horizontal" gap={4}>
                <Navbar.Text>
                    Пользователь: {username}
                </Navbar.Text>
            </Stack>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <div style={{position: 'absolute', right: '20px',top: '10px'}}>
            <button type="button"
                    className="btn btn-link"
                    data-toggle="button"
                    aria-pressed="false"
                    autoComplete="off"
                    type="button"
                    onClick={onLogout}>
            Выйти
            </button>
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Новая сборка</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="appName">
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => {
                                setSelectedApp(e.target.value)
                            }}
                        >
                                <option value="">Приложение</option>
                              {apps.map((item) => {
                                return(
                                    <option key={item.id} value={item.name}>{item.name}</option>
                                    )
                                })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="task">
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => {
                                setSelectedLane(e.target.value)
                            }}
                        >
                                <option value="">Задача Fastlane</option>
                              {fastlaneTasks.map((item) => {
                                return(
                                    <option key={item.id} value={item.name}>{item.name}</option>
                                    )
                                })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="buildType">
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => {
                                setSelectedBuildType(e.target.value)
                            }}
                        >
                                <option value="">Тип сборки</option>
                              {buildTypes.map((item) => {
                                return(
                                    <option key={item.id} value={item.name}>{item.name}</option>
                                    )
                                })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="branch">
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => {
                                setSelectedBranch(e.target.value)
                            }}
                        >
                                <option value="">Ветка</option>
                              {branches.map((item, i) => {
                                return(
                                    <option key={i} value={item}>{item}</option>
                                    )
                                })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="version">
                        <Form.Label>Версия</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Версия"
                            defaultValue={version}
                            onChange={(e) => setVersion(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="buildNumber">
                        <Form.Label>Номер сборки</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Номер сборки"
                            defaultValue={codeVersion}
                            onChange={(e) => setCodeVersion(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={onStartBuild}>
            Запустить
            </Button>
            </Modal.Footer>
        </Modal>

        <Container>
            <Table striped bordered hover responsive="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Приложение</th>
                  <th>Версия</th>
                  <th>Версия кода</th>
                  <th>Тип</th>
                  <th>Ветка</th>
                  <th>Задача</th>
                  <th>Пользователь</th>
                  <th>Дата/время</th>
                  <th>Статус</th>
                </tr>
              </thead>
              <tbody>
              {tableData.map((el, i) => {
                    return(
                        <tr key={el.id}>
                            <td>{i+1}</td>
                            <td>{el.App}</td>
                            <td>{el.Version}</td>
                            <td>{el.CodeVersion}</td>
                            <td>{el.Build}</td>
                            <td>{el.Branch}</td>
                            <td>{el.Lane}</td>
                            <td>{el.User}</td>
                            <td>{new Date(el.CreatedAt).toLocaleString('ru-RU')}</td>
                            <td>{el.Status}</td>
                        </tr>
                    )
                })}
              </tbody>
            </Table>
        </Container>
    </>
        )
}

export default Main
